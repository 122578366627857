import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  CatalogServiceDto,
  ServiceType as ServiceTypeDeprecated,
} from '@wix/bookings-uou-types';
import { EnrichedService } from '../../types/types';

export const showExplorePlansForServiceDeprecated = (
  service: CatalogServiceDto,
) =>
  service.pricingPlanInfo.pricingPlans.length > 0 &&
  service.type !== ServiceTypeDeprecated.COURSE &&
  !service.schedulePolicy.displayOnlyNoBookFlow &&
  !service.schedulePolicy.isPendingApprovalFlow;

export const showExplorePlansForService = (service: EnrichedService) =>
  service.payment?.pricingPlanIds?.length! > 0 &&
  service?.type !== ServiceType.COURSE &&
  !service.displayOnlyNoBookFlow &&
  !service.onlineBooking?.requireManualApproval;
